/*logo*/
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");
/* line 2, scss/_navigation.scss */
#logo {
  background: url("../pix/lib-headerlogo.png") no-repeat 0 0;
  background-size: 100%;
  margin: 0; }
  @media only screen and (min-width: 699px) {
    /* line 2, scss/_navigation.scss */
    #logo {
      margin-top: 40px; } }

/* line 12, scss/_navigation.scss */
#logo, #logo a {
  width: 290px;
  height: 47px; }
  @media only screen and (min-width: 699px) {
    /* line 12, scss/_navigation.scss */
    #logo, #logo a {
      height: 90px; } }
  @media only screen and (min-width: 767px) {
    /* line 12, scss/_navigation.scss */
    #logo, #logo a {
      width: 300px;
      height: 95px; } }
  @media only screen and (min-width: 1024px) {
    /* line 12, scss/_navigation.scss */
    #logo, #logo a {
      width: 380px; } }

/*wrap*/
/* line 31, scss/_navigation.scss */
#wrap {
  margin: 0 auto;
  max-width: 1440px; }
  @media only screen and (max-width: 1440px) {
    /* line 31, scss/_navigation.scss */
    #wrap {
      margin: 0;
      padding-left: 0;
      padding-right: 0; } }

@media only screen and (min-width: 607px) {
  /* line 42, scss/_navigation.scss */
  #opac-main {
    padding: 0; } }

/*search*/
/* line 50, scss/_navigation.scss */
#opac-main-search {
  min-height: 135px;
  background-color: #f0f0f0; }
  /* line 54, scss/_navigation.scss */
  #opac-main-search .span12 {
    position: relative;
    min-height: inherit;
    padding-top: 20px; }

/* line 61, scss/_navigation.scss */
.mastheadsearch {
  margin-bottom: 0;
  padding-bottom: 0;
  background: transparent; }
  /* line 66, scss/_navigation.scss */
  .mastheadsearch .input-append {
    width: 100%; }
    @media only screen and (min-width: 767px) {
      /* line 66, scss/_navigation.scss */
      .mastheadsearch .input-append {
        width: calc(65% - 70px); } }

/* line 75, scss/_navigation.scss */
#opac-main-search input[type=text] {
  margin: 1px 0 0 30px;
  width: 70%;
  height: 50px;
  background-color: #fff;
  border-width: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  padding-left: 20px;
  font-size: 20px;
  color: #000;
  border: 4px solid #ffdd00; }
  @media screen and (max-width: 767px) {
    /* line 75, scss/_navigation.scss */
    #opac-main-search input[type=text] {
      margin: 15px 0 0;
      width: 100%;
      height: 45px;
      background-color: #fff; } }
  @media screen and (min-width: 1024px) {
    /* line 75, scss/_navigation.scss */
    #opac-main-search input[type=text] {
      width: 80%; } }

/* line 104, scss/_navigation.scss */
.mastheadsearch select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: ''; }

/* line 111, scss/_navigation.scss */
.mastheadsearch #masthead_search {
  max-width: none;
  min-width: 180px;
  height: 50px;
  background-color: #3a3a3c;
  font-size: 18px;
  color: #fff;
  border: none;
  border-radius: 0;
  text-indent: 7px;
  background-image: url("../pix/chevron-down-white.png");
  background-size: 35px;
  background-position: right 10px center;
  background-position-y: 10px;
  background-repeat: no-repeat;
  font-family: 'Source Sans Pro', sans-serif; }
  @media only screen and (min-width: 767px) {
    /* line 111, scss/_navigation.scss */
    .mastheadsearch #masthead_search {
      max-width: 240px;
      width: calc(35% - 70px); } }
  @media screen and (max-width: 767px) {
    /* line 111, scss/_navigation.scss */
    .mastheadsearch #masthead_search {
      margin: 15px 0 0;
      width: 100%;
      height: 45px; } }

/* line 140, scss/_navigation.scss */
.mastheadsearch .search-submit {
  padding: 10px 30px;
  height: 50px;
  border: none;
  border-radius: 0;
  text-shadow: none;
  font-size: 16px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #3a3a3c !important;
  background-image: url("../pix/search-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -9999px;
  /* sends the text off-screen */
  outline: none;
  border: 4px solid #ffdd00; }
  @media only screen and (max-width: 608px) {
    /* line 140, scss/_navigation.scss */
    .mastheadsearch .search-submit {
      border-radius: 0 !important;
      width: 100% !important; } }
  @media only screen and (max-width: 767px) {
    /* line 140, scss/_navigation.scss */
    .mastheadsearch .search-submit {
      display: block;
      width: 100%;
      height: 45px;
      border-radius: 0; } }
  @media only screen and (min-width: 767px) {
    /* line 140, scss/_navigation.scss */
    .mastheadsearch .search-submit {
      border-left: none;
      margin-left: -10px;
      background-color: #fff !important; } }

/* line 176, scss/_navigation.scss */
.mastheadsearch label {
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center; }
  @media only screen and (min-width: 801px) {
    /* line 176, scss/_navigation.scss */
    .mastheadsearch label {
      display: inline-block;
      width: 110px;
      padding-bottom: 0; } }

/* line 190, scss/_navigation.scss */
#header-region .container-fluid:nth-child(1) {
  padding-left: 0;
  background-color: #f0f0f0; }
  @media only screen and (min-width: 699px) {
    /* line 190, scss/_navigation.scss */
    #header-region .container-fluid:nth-child(1) {
      background-color: #fff; } }

/* line 200, scss/_navigation.scss */
#header-region .container-fluid:nth-child(2) {
  background-color: #f0f0f0;
  border-top: none; }
  @media only screen and (min-width: 699px) {
    /* line 200, scss/_navigation.scss */
    #header-region .container-fluid:nth-child(2) {
      border-top: 35px solid #3a3a3c;
      margin-top: -84px; } }
  @media only screen and (min-width: 767px) {
    /* line 200, scss/_navigation.scss */
    #header-region .container-fluid:nth-child(2) {
      border-top: 35px solid #3a3a3c;
      margin-top: -88px; } }
  @media only screen and (min-width: 1024px) {
    /* line 200, scss/_navigation.scss */
    #header-region .container-fluid:nth-child(2) {
      margin-top: -81px; } }
  /* line 218, scss/_navigation.scss */
  #header-region .container-fluid:nth-child(2) #opacheader {
    background-color: #f0f0f0;
    padding: 35px 30px 25px; }
    @media screen and (max-width: 698px) {
      /* line 218, scss/_navigation.scss */
      #header-region .container-fluid:nth-child(2) #opacheader {
        padding: 10px; } }

/* line 231, scss/_navigation.scss */
#navigation-links {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-left: 0; }
  @media screen and (max-width: 698px) {
    /* line 231, scss/_navigation.scss */
    #navigation-links {
      justify-content: flex-start;
      flex-direction: column; } }
  /* line 244, scss/_navigation.scss */
  #navigation-links .nav-link {
    color: #3a3a3c;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: .5px;
    cursor: pointer; }
  /* line 252, scss/_navigation.scss */
  #navigation-links li {
    margin-left: 25px; }
    @media screen and (max-width: 698px) {
      /* line 252, scss/_navigation.scss */
      #navigation-links li {
        margin-left: 0; } }
  @media screen and (max-width: 698px) {
    /* line 260, scss/_navigation.scss */
    #navigation-links li:nth-child(2) {
      margin-top: 20px; } }
  /* line 271, scss/_navigation.scss */
  #navigation-links .dropdown .dropdown-menu li {
    margin-left: 0; }
    /* line 274, scss/_navigation.scss */
    #navigation-links .dropdown .dropdown-menu li:hover, #navigation-links .dropdown .dropdown-menu li:focus {
      color: #3a3a3c; }
  /* line 281, scss/_navigation.scss */
  #navigation-links .dropdown .fa.fa-icon-black,
  #navigation-links .dropdown .fa.fa-icon-white {
    color: #3a3a3c; }
  /* line 286, scss/_navigation.scss */
  #navigation-links .dropdown a {
    color: #3a3a3c; }
    /* line 291, scss/_navigation.scss */
    #navigation-links .dropdown a:hover, #navigation-links .dropdown a:focus {
      text-decoration: none;
      color: #3a3a3c; }
  @media screen and (max-width: 698px) {
    /* line 300, scss/_navigation.scss */
    #navigation-links .dropdown.open .dropdown-menu {
      left: 0; } }
  /* line 307, scss/_navigation.scss */
  #navigation-links .listslabel,
  #navigation-links .caret,
  #navigation-links .langlabel {
    display: none; }

/* line 314, scss/_navigation.scss */
.dropdown-menu {
  right: 0;
  left: auto; }

/* line 319, scss/_navigation.scss */
.topnav .icon {
  display: none; }

/* line 323, scss/_navigation.scss */
.icon .fa {
  font-size: 20px;
  color: #3a3a3c; }

@media screen and (max-width: 698px) {
  /* line 332, scss/_navigation.scss */
  .topnav a {
    display: none; }
  /* line 336, scss/_navigation.scss */
  .topnav .icon {
    display: block; }
  /* line 341, scss/_navigation.scss */
  .topnav.responsive {
    position: relative; }
    /* line 344, scss/_navigation.scss */
    .topnav.responsive a {
      position: relative;
      display: block;
      padding: 15px 35px; }
    /* line 350, scss/_navigation.scss */
    .topnav.responsive .icon {
      padding: 0; }
    /* line 354, scss/_navigation.scss */
    .topnav.responsive a.icon {
      position: absolute;
      left: 0;
      top: 0; } }
  @media screen and (max-width: 698px) and (max-width: 698px) {
    /* line 360, scss/_navigation.scss */
    .topnav.responsive li:not(li:first-child) {
      border-top: 1px solid #cacaca; } }

/* line 370, scss/_navigation.scss */
#moresearches {
  margin: 0;
  padding: 10px 13px;
  background-color: transparent;
  text-align: left; }
  @media screen and (min-width: 801px) {
    /* line 370, scss/_navigation.scss */
    #moresearches {
      position: absolute;
      left: 45%;
      max-width: 1120px;
      margin: 0 auto;
      padding: 9px 0; } }
  @media screen and (min-width: 900px) {
    /* line 370, scss/_navigation.scss */
    #moresearches {
      left: 405px; } }
  /* line 388, scss/_navigation.scss */
  #moresearches a {
    color: #3a3a3c;
    font-size: 15px;
    margin-right: 20px;
    margin-left: 20px; }
    /* line 394, scss/_navigation.scss */
    #moresearches a:hover, #moresearches a:focus {
      color: #00a1f2;
      text-decoration: underline; }
  /* line 401, scss/_navigation.scss */
  #moresearches li:after {
    color: #3a3a3c; }

/* line 407, scss/_navigation.scss */
.navbar .nav {
  float: right; }
  /* line 410, scss/_navigation.scss */
  .navbar .nav .dropdown-menu {
    right: 0;
    left: auto; }
  /* line 415, scss/_navigation.scss */
  .navbar .nav > li > .dropdown-menu:before {
    left: auto;
    right: 9px; }
  /* line 420, scss/_navigation.scss */
  .navbar .nav > li > .dropdown-menu:after {
    left: auto;
    right: 10px; }

/* line 426, scss/_navigation.scss */
.nav .fa-icon-white,
.navbar-inverse .nav > li > a {
  color: #3a3a3c; }

/* line 431, scss/_navigation.scss */
.nav .divider-vertical {
  visibility: hidden; }

/* line 437, scss/_navigation.scss */
.navbar-inverse .navbar-inner {
  background-color: #fff;
  border: none;
  padding-left: 0; }
  /* line 442, scss/_navigation.scss */
  .navbar-inverse .navbar-inner ul.dropdown-menu a:hover {
    color: #3a3a3c; }
  /* line 446, scss/_navigation.scss */
  .navbar-inverse .navbar-inner li > a {
    font-weight: 400; }

/* line 451, scss/_navigation.scss */
.navbar-inverse .nav > li > a:focus,
.navbar-inverse .nav > li > a:hover,
.navbar-inverse .nav li.dropdown.open > .dropdown-toggle {
  color: #535356; }

/* line 457, scss/_navigation.scss */
.navbar-inverse .nav li.dropdown > a:focus .caret,
.navbar-inverse .nav li.dropdown > a:hover .caret {
  border-top-color: #535356;
  border-bottom-color: #535356; }

/* line 463, scss/_navigation.scss */
.navbar-inverse .nav li.dropdown.open > .dropdown-toggle .caret,
.navbar-inverse .nav li.dropdown.open > .dropdown-toggle .caret:hover {
  border-top-color: #535356;
  border-bottom-color: #535356; }

/* line 471, scss/_navigation.scss */
.dropdown-menu {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-color: #f0f0f0; }

/* line 476, scss/_navigation.scss */
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  background: #f0f0f0; }

/* line 482, scss/_navigation.scss */
.breadcrumb {
  background-color: transparent;
  font-size: 100%;
  margin: 10px 10px 30px; }
  /* line 487, scss/_navigation.scss */
  .breadcrumb li a {
    color: #818a91; }
    /* line 490, scss/_navigation.scss */
    .breadcrumb li a:hover, .breadcrumb li a:focus, .breadcrumb li a:active {
      color: #00a1f2;
      text-decoration: underline; }

/* line 500, scss/_navigation.scss */
#menu {
  border-right: none; }
  /* line 503, scss/_navigation.scss */
  #menu li a {
    margin: .6em 0;
    padding: .6em;
    border: 1px solid #f0f0f0; }
    /* line 508, scss/_navigation.scss */
    #menu li a:hover, #menu li a:focus {
      background-color: #fff;
      border: 1px solid #f0f0f0; }
  /* line 515, scss/_navigation.scss */
  #menu li.active a {
    border: 1px solid #f0f0f0; }

/* line 520, scss/_navigation.scss */
#members p {
  color: #3a3a3c; }

/* line 524, scss/_navigation.scss */
.login-link {
  color: #3a3a3c !important; }

/* line 529, scss/_navigation.scss */
select:focus,
.btn:focus {
  outline: none; }

/* line 3, scss/_base.scss */
.main {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  padding: 20px; }

/* line 11, scss/_base.scss */
body {
  background-color: #fff;
  font-family: 'Source Sans Pro', sans-serif !important; }

/* line 16, scss/_base.scss */
#wrap {
  max-width: none;
  padding-left: 0;
  padding-right: 0; }

/* line 22, scss/_base.scss */
#header-region + .container-fluid {
  padding-right: 0;
  padding-left: 0; }

/* line 27, scss/_base.scss */
h1, h2, h3, h4, h5, h6, legend {
  color: #373a3c;
  font-weight: 400; }

/* line 32, scss/_base.scss */
a {
  color: #00a1f2; }
  /* line 35, scss/_base.scss */
  a:visited {
    color: #00a1f2; }
  /* line 39, scss/_base.scss */
  a:hover, a:focus {
    color: #40bfff; }

/* line 1, scss/_login.scss */
input[type=password]:focus,
input[type=text]:focus {
  border-color: none;
  box-shadow: none; }

/* line 7, scss/_login.scss */
#login {
  padding: 20px; }
  /* line 10, scss/_login.scss */
  #login legend {
    font-size: 1.5rem; }
  /* line 14, scss/_login.scss */
  #login label {
    font-size: 16px; }
  /* line 18, scss/_login.scss */
  #login input[type="text"],
  #login input[type="password"] {
    height: 40px;
    background-color: #f0f0f0;
    border: none;
    box-shadow: none;
    padding: 3px 20px;
    width: 85%;
    border-radius: 0px; }
  /* line 29, scss/_login.scss */
  #login input.btn[type="submit"] {
    box-shadow: none;
    border: none;
    border-radius: 0px;
    background-color: #ffdd00;
    color: #000;
    width: 100%;
    background-image: none;
    text-shadow: none;
    height: 40px;
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif; }
    /* line 43, scss/_login.scss */
    #login input.btn[type="submit"]:hover, #login input.btn[type="submit"]:focus {
      background-color: #ffe74d; }

/* line 4, scss/_search-results.scss */
.pagination ul > .active > a {
  background-color: #00a1f2;
  border-color: #00a1f2;
  color: #fff; }

/* line 10, scss/_search-results.scss */
.pagination ul > li > a {
  padding: 9px 15px;
  margin-right: 4px;
  border-left-width: 1px;
  border-radius: 3px; }
  /* line 16, scss/_search-results.scss */
  .pagination ul > li > a:hover, .pagination ul > li > a:focus {
    background-color: #00a1f2;
    border-color: #00a1f2;
    color: #fff; }

/* line 27, scss/_search-results.scss */
.searchresults #toolbar.toolbar,
.searchresults #toolbar.toolbar.clearfix {
  background-color: #f0f0f0;
  border: none; }

/* line 33, scss/_search-results.scss */
.searchresults #selections-toolbar {
  background: #f0f0f0;
  padding-top: 2em;
  padding-bottom: 2em;
  border: none; }

/* line 40, scss/_search-results.scss */
.searchresults #selections {
  font-weight: 400;
  font-size: 1rem; }

/* line 45, scss/_search-results.scss */
.searchresults .toolbar {
  padding: 10px; }
  /* line 48, scss/_search-results.scss */
  .searchresults .toolbar select {
    height: 30px;
    border: none; }

/* line 54, scss/_search-results.scss */
.searchresults input.btn[type="submit"] {
  background-color: #00a1f2;
  color: #fff;
  background-image: none;
  box-shadow: none;
  text-shadow: none;
  border: none; }

/* line 63, scss/_search-results.scss */
.searchresults .selectcol {
  padding-top: 20px; }

/* line 67, scss/_search-results.scss */
.searchresults .table td {
  border-top: 1px solid #f0f0f0; }

/* line 72, scss/_search-results.scss */
.searchresults .table-striped tbody > tr:nth-child(odd) > td,
.searchresults .table-striped tbody > tr:nth-child(odd) > th {
  background-color: #fff; }

/* line 77, scss/_search-results.scss */
.searchresults a.title {
  line-height: 25px;
  font-size: 1.2rem;
  font-weight: 400;
  color: #373a3c; }

/* line 84, scss/_search-results.scss */
.searchresults .bibliocol {
  padding: 20px 50px 20px 20px; }
  /* line 87, scss/_search-results.scss */
  .searchresults .bibliocol p, .searchresults .bibliocol span {
    margin-top: 10px;
    margin-bottom: 8px;
    font-size: 14px; }

/* line 96, scss/_search-results.scss */
#search-facets {
  border: none;
  margin-top: 15px;
  padding: 0 15px; }
  /* line 101, scss/_search-results.scss */
  #search-facets h4 a {
    margin: -15px;
    background-color: #ffdd00;
    color: #000;
    text-transform: uppercase;
    font-size: 16px;
    border-bottom: none;
    font-weight: 400;
    margin-bottom: 15px; }
    /* line 111, scss/_search-results.scss */
    #search-facets h4 a:hover, #search-facets h4 a:focus {
      background-color: #ffe74d;
      color: #000;
      text-decoration: none; }
  /* line 119, scss/_search-results.scss */
  #search-facets h5 {
    font-size: 1rem;
    color: #000;
    margin-bottom: 0; }
  /* line 125, scss/_search-results.scss */
  #search-facets a {
    color: #999;
    text-decoration: none; }
    /* line 129, scss/_search-results.scss */
    #search-facets a:hover, #search-facets a:focus {
      color: #00a1f2;
      text-decoration: underline; }

@media screen and (min-width: 768px) {
  /* line 137, scss/_search-results.scss */
  #results .span2 {
    width: 30%; } }

@media screen and (min-width: 1300px) {
  /* line 137, scss/_search-results.scss */
  #results .span2 {
    width: 25%; } }

@media screen and (min-width: 768px) {
  /* line 147, scss/_search-results.scss */
  #results .row-fluid .span10 {
    width: 65%; } }

@media screen and (min-width: 1300px) {
  /* line 147, scss/_search-results.scss */
  #results .row-fluid .span10 {
    width: 70%; } }

/* line 159, scss/_search-results.scss */
#opac-detail .span3 {
  margin-left: 0; }

/* line 163, scss/_search-results.scss */
#opac-detail .span9 {
  float: right; }

/* line 169, scss/_search-results.scss */
#advsearches {
  background-color: #f0f0f0;
  border-radius: 0;
  margin: 20px 0; }
  /* line 174, scss/_search-results.scss */
  #advsearches #advsearch-tab-itemtypes {
    border: 0;
    background-color: transparent; }
  /* line 179, scss/_search-results.scss */
  #advsearches #advsearch-itemtypes {
    border: 0 !important; }
    /* line 182, scss/_search-results.scss */
    #advsearches #advsearch-itemtypes .span3 {
      padding-bottom: 25px; }

/* line 188, scss/_search-results.scss */
legend {
  color: #000; }

/* line 192, scss/_search-results.scss */
.searchresults .bibliocol .term {
  font-size: 1.2rem; }

/* line 2, scss/_book-details.scss */
#views {
  border-color: #eee; }
  /* line 5, scss/_book-details.scss */
  #views .view {
    border-right-width: 1px;
    border-top-width: 1px;
    border: 1px solid #eee;
    border-bottom: none;
    background-color: transparent; }
    /* line 12, scss/_book-details.scss */
    #views .view a, #views .view span {
      background-image: none;
      padding-left: 0; }

/* line 20, scss/_book-details.scss */
.nav_results {
  border: 0; }
  /* line 23, scss/_book-details.scss */
  .nav_results .l_Results {
    display: none !important; }
  /* line 27, scss/_book-details.scss */
  .nav_results .pg_menu {
    background-color: transparent;
    border: 0; }
    /* line 31, scss/_book-details.scss */
    .nav_results .pg_menu li span,
    .nav_results .pg_menu li a {
      background-color: transparent;
      border: 0; }

/* line 39, scss/_book-details.scss */
#action {
  background-color: #fff;
  border: none;
  padding: 10px;
  margin: .9em 0 0; }
  /* line 45, scss/_book-details.scss */
  #action li {
    padding: .9em 0;
    border-top: 1px solid #eee; }
  /* line 50, scss/_book-details.scss */
  #action a {
    color: #3a3a3c;
    font-weight: 400; }

/* line 57, scss/_book-details.scss */
th, .table-bordered thead:first-child tr:first-child th {
  background-color: #f0f0f0 !important;
  color: #3a3a3c !important; }

/* line 1, scss/_buttons.scss */
.btn,
button.btn,
input.btn[type="submit"] {
  background-color: #ffdd00 !important;
  color: #000 !important;
  text-shadow: none;
  border: none;
  box-shadow: none;
  background-image: none;
  border-radius: 0;
  padding: 8px 23px;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif; }
  /* line 16, scss/_buttons.scss */
  .btn:hover, .btn:focus,
  button.btn:hover,
  button.btn:focus,
  input.btn[type="submit"]:hover,
  input.btn[type="submit"]:focus {
    background-color: #ffe74d !important; }

/* line 24, scss/_buttons.scss */
.main .input-append input.btn[type="submit"] {
  padding: 5px 23px; }

/* line 29, scss/_buttons.scss */
.slick-prev,
.slick-next {
  color: #ffdd00 !important; }

/* line 1, scss/_tabs.scss */
#opaccustomsearch {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  /* line 6, scss/_tabs.scss */
  #opaccustomsearch .span9 {
    max-width: 800px; }
  /* line 10, scss/_tabs.scss */
  #opaccustomsearch #tabs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0; }
    /* line 18, scss/_tabs.scss */
    #opaccustomsearch #tabs li {
      background-color: #ffdd00;
      margin-left: 0.5rem;
      padding: 9.5px 25px;
      text-align: center;
      color: #3a3a3c;
      font-size: 17px;
      font-weight: bold;
      cursor: pointer; }
      @media only screen and (min-width: 1075px) {
        /* line 18, scss/_tabs.scss */
        #opaccustomsearch #tabs li {
          width: 50%;
          max-width: 346px; } }
      /* line 34, scss/_tabs.scss */
      #opaccustomsearch #tabs li:first-child {
        margin-left: 0; }
      /* line 38, scss/_tabs.scss */
      #opaccustomsearch #tabs li.inactive {
        background-color: #3a3a3c;
        color: #fff; }
        /* line 42, scss/_tabs.scss */
        #opaccustomsearch #tabs li.inactive:hover, #opaccustomsearch #tabs li.inactive:focus {
          background-color: #212122; }

/* line 51, scss/_tabs.scss */
#moresearches {
  position: inherit;
  max-width: 570px; }

/* line 58, scss/_tabs.scss */
.mastheadsearch {
  margin: 0;
  padding: 0; }

/* line 63, scss/_tabs.scss */
#opac-main-search {
  min-height: 161px; }
  /* line 68, scss/_tabs.scss */
  #opac-main-search .span12 {
    padding-top: 0; }

/* line 73, scss/_tabs.scss */
.search-container {
  background-color: #ffdd00;
  margin: 0;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  padding: 4px 0 0 0; }
  /* line 81, scss/_tabs.scss */
  #opac-main-search .search-container input[type=text] {
    margin: 0;
    flex-grow: 1;
    width: auto; }
    @media only screen and (max-width: 767px) {
      /* line 81, scss/_tabs.scss */
      #opac-main-search .search-container input[type=text] {
        font-size: 18px; } }
  /* line 91, scss/_tabs.scss */
  .search-container .tab-advanced-search.adv-search {
    width: 100%;
    background-color: #f0f0f0;
    padding-top: 7px; }
    @media only screen and (max-width: 767px) {
      /* line 91, scss/_tabs.scss */
      .search-container .tab-advanced-search.adv-search {
        padding-left: 13px; } }

/* line 105, scss/_tabs.scss */
#usermenu .menu-collapse {
  background: #fff;
  border: 2px solid #03a1f4; }

/* line 110, scss/_tabs.scss */
#usermenu #menu ul {
  padding: 0; }

/* line 114, scss/_tabs.scss */
#usermenu #menu li.active a {
  border-bottom: 1px solid #03a1f4;
  background-color: #03a1f4;
  color: white; }

/* line 120, scss/_tabs.scss */
#usermenu #menu li a {
  border: none;
  border-bottom: 1px solid #eaeaea;
  background-color: #fff;
  padding: 1em;
  margin: 0; }

/* line 3, scss/_dashboard.scss */
#opac-main .main {
  border: 0; }

/* line 8, scss/_dashboard.scss */
#new_titles {
  padding: 20px;
  margin: 5px;
  border: 2px solid #f0f0f0; }
  /* line 13, scss/_dashboard.scss */
  #new_titles h2 {
    font-weight: bold;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 7px;
    margin-bottom: 25px; }
  /* line 20, scss/_dashboard.scss */
  #new_titles #new_orders {
    border-top: 0; }

/* line 25, scss/_dashboard.scss */
.comingsoon-items-container {
  display: flex;
  flex-wrap: wrap; }
  /* line 29, scss/_dashboard.scss */
  .comingsoon-items-container h2 {
    font-weight: bold;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 7px;
    margin-bottom: 25px; }
  /* line 36, scss/_dashboard.scss */
  .comingsoon-items-container #new_orders {
    border-top: none;
    padding: 20px;
    margin: 5px;
    border: 2px solid #f0f0f0;
    width: 100%; }
    @media screen and (min-width: 767px) {
      /* line 36, scss/_dashboard.scss */
      .comingsoon-items-container #new_orders {
        width: calc(50% - 54px); } }
  /* line 48, scss/_dashboard.scss */
  .comingsoon-items-container #just_arrived {
    padding: 20px;
    margin: 5px;
    border: 2px solid #f0f0f0;
    width: 100%; }
    @media screen and (min-width: 767px) {
      /* line 48, scss/_dashboard.scss */
      .comingsoon-items-container #just_arrived {
        width: calc(50% - 54px); } }
  /* line 59, scss/_dashboard.scss */
  .comingsoon-items-container #just_arrived_limit,
  .comingsoon-items-container #new_orders_limit {
    width: 100%;
    border: 0;
    background-color: #f0f0f0;
    border-radius: 0;
    padding: 8px;
    height: 40px; }

/* line 70, scss/_dashboard.scss */
#search-libguide {
  margin: 5px;
  border: 2px solid #f0f0f0;
  padding: 20px; }
  /* line 75, scss/_dashboard.scss */
  #search-libguide h2 {
    font-weight: bold;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 7px;
    margin-bottom: 25px; }
  /* line 82, scss/_dashboard.scss */
  #search-libguide #s-lg-embedded-search-widget-1572813233633 .s-lg-form-control {
    background-color: #f0f0f0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    height: 40px;
    padding-left: 8px 14px;
    font-size: 1.1em;
    margin: 0;
    width: calc(100% - 51px); }
    @media screen and (min-width: 767px) {
      /* line 82, scss/_dashboard.scss */
      #search-libguide #s-lg-embedded-search-widget-1572813233633 .s-lg-form-control {
        width: calc(50% - 75px); } }
  /* line 98, scss/_dashboard.scss */
  #search-libguide button.s-lg-btn-api-drop.btn.btn-default {
    width: 50px;
    height: 40px;
    text-indent: -9999px;
    background-color: #f0f0f0 !important;
    margin-left: -4px;
    background-image: url("../pix/search-icon.svg");
    background-repeat: no-repeat;
    background-position: center; }

/* line 111, scss/_dashboard.scss */
#opac-main .span3 {
  padding: 20px;
  border: 2px solid #f0f0f0;
  margin: 5px; }

/* line 3, scss/_footer.scss */
#changelanguage .navbar-inner {
  background-color: #ffdd00;
  border-top: 0;
  height: 80px;
  padding-top: 20px; }

/* line 10, scss/_footer.scss */
#changelanguage .lang.navbar-text,
#changelanguage .navbar-text {
  color: #3a3a3c; }

/* line 15, scss/_footer.scss */
#changelanguage #i18nMenu li a {
  color: #3a3a3c;
  text-shadow: none; }
  /* line 19, scss/_footer.scss */
  #changelanguage #i18nMenu li a:hover, #changelanguage #i18nMenu li a:focus {
    text-decoration: underline;
    color: #3a3a3c;
    text-shadow: none; }
